import { Routes, Route } from "react-router-dom";
import loadable from '@loadable/component';

const HomeScreen = loadable(() => import('../screens/home.screen'))
const ContactUsScreen = loadable(() => import('../screens/contact-us.screen'))
const AboutUsScreen = loadable(() => import('../screens/about-us.screen'))
const GalleryScreen = loadable(() => import('../screens/gallery.screen'))
const PrivacyPolicy = loadable(() => import('../screens/privacy-policy.screen'))

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen />}></Route>
      <Route path="/home" element={<HomeScreen />}></Route>
      <Route path="/contact-us" element={<ContactUsScreen />}></Route>
      <Route path="/about-us" element={<AboutUsScreen />}></Route>
      <Route path="/gallery" element={<GalleryScreen />}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
    </Routes>
  )
}